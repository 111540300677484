import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import IntlMessages from 'util/IntlMessages';
import { API_BASE_URL } from '../../../constants';

const CONFIRM_URL = `${API_BASE_URL}user/confirm-email/`;

const ConfirmEmail = ({ match }) => {
  const [message, setMessage] = useState('');
  const { uid, token } = match.params;

  const verifyRequest = (uid, token) => {
    axios.post(CONFIRM_URL, {
      uid,
      token
    })
      .then(res => setMessage(res.data.detail))
      .catch(error => setMessage('something went wrong'));
  };

  useEffect(() => {
    verifyRequest(uid, token);
  }, [uid]);


  return (
    <div className="
        app-login-container
        d-flex
        justify-content-center
        align-items-center
        animated
        slideInUpTiny
        animation-duration-3
        ">
      <div className="app-login-main-content">

        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="LobiBox">
            <img src={require('assets/images/LB-Logo.png')} alt="LobiBox" title="LobiBox" />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1><IntlMessages id="appModule.loginHeader" /></h1>
          </div>

          <div className="d-flex justify-content-center align-items-center ">
            <h1>{message}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
